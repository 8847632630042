.user-layout {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    height: 100px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto;
    padding: 12px 0px;
    background-color: white;
    div {
      display: flex;
      gap: 4px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      .logo {
        height: 32px;
      }

      .home-logo{
        height: 80px;
      }

      button {
        height: 40px;
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
  .content {
    margin-top: 70px;
    flex-grow: 1;
    background-color: #f7f7f7;
  }
  .footer {
    padding: 0px;
    border-top: unset;
    position: static;
    height: unset;
    .footer-nav {
      background-color: #0a3c64;
      .nav-wrapper {
        .title {
          color: #ffffff;
          font-size: 1rem;
          font-weight: 400;
          text-transform: uppercase;
          margin-bottom: 0px;
          line-height: 1;
        }
        .nav-item {
          color: #ffffff;
          padding-left: 6px;
          padding-right: 6px;
          padding-top: 2px;
          padding-bottom: 2px;
          font-size: 1rem;
          font-weight: 400;
          margin-bottom: 0px;
          cursor: pointer;
          &:hover {
            background-color: $user-layout-primary;
          }
        }
      }
    }
    .bottom-footer {
      background-color: #073153;
      padding: 20px 10px;
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        gap: 6px;
      }
      & > span {
        color: #ffffff;
        font-size: 0.8rem;
      }
    }
  }
}
