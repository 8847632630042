//primary btn
.ant-btn.primary-btn {
  color: #ffffff;
  background-color: $user-layout-accent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.ant-btn-primary.primary-btn:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: $user-layout-primary;
}

//hover-zooming-effect
.hover-zoom-effect {
  @media (min-width: 600px) {
    &:hover {
      transform: scale(1.1);
      transition: transform 0.3s;
    }
  }
}
