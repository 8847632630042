.home-page {
  .home-slider {
    .slide-container {
      position: relative;
      min-height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      --container-widget-width: 100%;
      --container-widget-height: initial;
      --container-widget-flex-grow: 0;
      --container-widget-align-self: initial;
      --background-transition: 0.3s;
      --overlay-opacity: 0.88;

      &::before {
        content: " ";
        --background-overlay: "";
        background-color: transparent;
        background-image: linear-gradient(90deg, #13548a 28%, #1b74bd00 63%);
      }

      .slide-img {
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: scale(1);
        transition: 0s linear;
      }

      .slide-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 10px;

        .title-1 {
          color: #ffffff;
          font-size: 4rem;
          margin-bottom: 5;
          line-height: 1;
          text-transform: uppercase;
        }

        .title-2 {
          color: #ffffff;
          font-size: 2rem;
          margin-bottom: 7;
          line-height: 1;
        }

        .description {
          margin-bottom: 32px;
          color: #ffffff;
          font-size: 19px;
          line-height: 28px;
        }

        .btn {
          color: $user-layout-primary;
          background-color: #ffffff;
          font-size: 1.6rem;
          font-weight: 700;
          padding: 10px 40px;
          border-radius: 5px;
          height: 65px;

          &:hover {
            color: #ffffff;
            background-color: $user-layout-accent;
          }
        }
      }

      .layer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;

        &::before {
          content: " ";
          --background-overlay: "";
          background-color: transparent;
          background-image: linear-gradient(90deg, #13548a 28%, #1b74bd00 63%);
          height: 100%;
          width: 100%;
        }
      }
    }

    // image zooming animation
    .slick-active.slick-current {
      .slide-img {
        transform: scale(1.1);
        transition: 6s linear;
      }
    }
  }

  .feature-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .thumbnail {
      width: 100%;
      aspect-ratio: 1.2/1;
      object-fit: cover;
    }

    .icon {
      flex-shrink: 0;
      height: 26px;
      width: 26px;
      color: #42AAFF;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .title {
      font-size: 1.8rem;
      font-weight: 600;
      color: $user-layout-primary;
      margin-bottom: 0;
    }

    .description {
      font-size: 19px;
      flex-grow: 1;
    }
  }
}

.error-text {
  color: red;
}

.review-card {
  border: solid 1px lightgray;
  border-radius: 4px;
  padding: 20px;
  .review-card-title {
    font-size: 20px;
    font-weight: 500;
  }
}

.review-footer {
  display: flex;
  justify-content: space-between;
}

.review-edit {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.surrogate-select .ant-select-selector,
.surrogate-select .ant-select-selection-item {
  height: 40px !important;
}

.surrogate-select .ant-select-dropdown {
  max-height: 200px;
}

.surrogate-select .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.footer-navbar {
  list-style: none;
  font-size: 16px;
  padding: 0px;

  li {
    padding: 10px 20px;
    &:hover {
      background-color: #1B74BD;
    }

    a {
      color: #fff;
    }
  }
}